.bgOpenMenu {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(128,128,128 , 0.8);
    z-index: 998;
    top: 0;
    left: 0;
    bottom: 0;
}

.openMenu {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;    
    padding: 10px
}


.mainRow {
    width:100%;
    height: 100%;
    /*position: fixed;*/
}
.row-1 {
margin: 0px auto;
width: 60%;
margin-top: 10px;
position: relative;
}

.mainBarNewCon {
    border: 1px black solid;
    width: 100%;
    }

@media screen and (max-width: 1200px) and (min-width: 768px) {
    .row-1 {
        width: 70%;
        } 
}

@media screen and ( max-width:768px) {
    .row-1 {
        width: 100%;
        } 

@media screen and ( max-width:576px) {
        .openMenu , .input-group-text , .form-control , .form-select {
            font-size: 0.8rem !important;
        }

        
        }
}

.topBar {
padding:5px; 
background-color: #fff ;    
}

.centralBarText {
 background-color:#f0f0f0 ;
}

.dialogBoxCentralBar {
    padding: 1rem;
    background-color:#f0f0f0 ;
}

.dialogBoxCentralBar > * {
    padding-bottom: 0.5rem;
}

.bottomBar {
background-color:#d8d8d8 ; 
/*border-top: 1px solid black;*/   
display:block; 
min-height:51px;    
}

.textareaMessageBox {
    background-color: #f0f0f0;
}

.textareaMessageCounter {
    font-size: 0.8rem;
}

.redText {
    color: red;
}

.buttons_main {
    float: right;
    margin: 10px 30px 10px 0px;    
}
.buttons_main >* {
margin: 0px 5px 0px 5px;    
}

.mainBarDelCon {
    border: 1px black solid;
    width: 100%;
}

.mainBarVisit {
    border: 1px black solid;
    width: 100%;
}

.mainBarDialogBox {
    border: 1px black solid;
    width: 100%;
}

.btn-xsm {
    padding: 0.2rem 0.4rem !important;
    font-size: .75rem !important;
    border-radius: 0.2rem !important;
    height: fit-content !important
}

.buttonsBox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.consumerVisitsBox {
    width: 100%;
    background-color: white
}

.visitsHistory {
    height: 6.5rem;
    overflow-y: auto
}

.searchIcon {
    width: 1rem;
    height: 1rem;
    background-image: url("/images/search_64b.png");
    background-size: contain;
    cursor: pointer;
}

.visitRow {
    display: flex;
    align-items: center
}
.visitRowSeconds {
    background-color: #e1deff;
}

.loadingWrapp {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.loading { 
    background-color: rgb(223, 223, 223) !important
}

