@import url(https://fonts.googleapis.com/css?family=Roboto:300);

.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}
.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.btnLogin {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #3214a1;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
}
.btnLogin:hover, .btnLogin:active, .btnLogin:focus {
  background: #8766ff;
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #3214a1;
  text-decoration: none;
}
.form .register-form {
  display: none;
}
.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.container:before, .container:after {
  content: "";
  display: block;
  clear: both;
}
.container .info {
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #EF3B3A;
}
.mainLoginPage {
    width: 100%;
    height: 100%;
  background: #3214a1; /* fallback for old browsers */
  background:  rgb(50 20 161);
  background: linear-gradient(90deg, rgb(50 20 161) 0%, rgb(82 84 184) 50%);
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;      
}
.row {
    margin-right: 0px !important
}
.loadingMainBox , .errorMainBox , .newDeviceMainBox {
    position: fixed;
    z-index: 1001;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.8)
}

.loadingDivBox , .errorDivBox , .newDeviceDivBox {
    position: relative;
    top: 50%
}

.loadingTextBox , .errorTextBox , .newDeviceTextBox {
padding: 20px;
margin: 0px auto;
border: 1px solid black;
width: 20%;
background: #3e2bf0
}

.errorTextBox {
    background-color: crimson;
}

.loadingText , .errorText {
    color: white
}


.alertInfoMainBox {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #3e2bf0;
    z-index: 100;
}

.alertInfoFixed {
    position: absolute;
}
.top {
background-color: #3e2bf0;   
color: white; 
font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.infoPanel {
    padding: 2px 6px;
}

.salonName {
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
}


.loginText {
font-size: 0.8rem;
font-weight: 400;
line-height: 1.5;   
}

.visibleBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.visibleMenuRow {
    height: 100%;
}
.visibleMenu {
    background-color: #503eff;
    height: 100%;
}
.visibleBtn {
    cursor: "pointer"
}

.rightPanel {
    display: flex;
}
.userPanel {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.logoutIcon {
    cursor: pointer;
}

.networkIcon , .alertIcon , .logoutIcon{
    width: 1.5rem;
    height: 1.5rem;
}

.alertIcon {
    cursor: pointer;
}

.h100 {
    height: 100%;
}

.clockDiv {
    padding: 0.9rem; 
    text-align: -webkit-right
}

.clockText {
    border: 1px solid #b1b1b1; 
    padding: 0.7rem; 
    width: -webkit-fit-content; 
    width: -moz-fit-content; 
    width: fit-content;
    font-size: 1rem
    
}

.centralBarMessagePanel {
    padding: 1rem;
}

.colorCentralBarMessagePanel {
    background-color: #f0f0f0 !important
}

@media screen and (max-width: 1200px) and (min-width: 768px) {

}
@media screen and (max-width: 922px) {
    .alertIcon , .networkIcon , .logoutIcon  {
    width: 1.2rem;
    height: 1.2rem;
    }

    .smsToSends {
        font-size: 0.6rem;
    }

    .clockText {
        font-size: .7rem;
        padding: .5rem 
    }


}

@media screen and (max-width: 768px) {
    .visibleMenuMain {
        display: none;
      }  
    .smsToSends {
        display: none;
    }
    .hidden_sm {
        display: none;
    }
    .clock {
        display: none;
    }

  }

@media screen and (min-width: 768px) {
    .visibleMenuMain {
      display: block;
    }
} 

@media screen and ( max-width:576px) {
    .salonName {
        font-size: 0.8rem;
    } 
    .loginText {
        font-size: 0.6rem;
    } 
} 

/*animacja*/
.header {
    position: relative;
    width: 100%;
    height: 80px;
    /*background: linear-gradient(135deg, #3e2bf0, #2b1db0);*/
    background: linear-gradient(135deg, #3e2bf0, #090527);
    overflow: hidden;
}

.header::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    top: 0;
    left: -50%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 10%, transparent 70%);
    -webkit-animation: waveMove 4s linear infinite alternate;
            animation: waveMove 4s linear infinite alternate;
    pointer-events: none; /* PRZEPUŚĆ KLIKNIĘCIA */
}

@-webkit-keyframes waveMove {
    0% { transform: translateX(-10%) translateY(0); }
    100% { transform: translateX(10%) translateY(5px); }
}

@keyframes waveMove {
    0% { transform: translateX(-10%) translateY(0); }
    100% { transform: translateX(10%) translateY(5px); }
}
 
#cal_button,
#work_button,
#cons_button,
#sett_button {
  padding: 5px;
  background-color: #ccc;
  margin: 2px;
}

#cal_button,
#work_button,
#cons_button ,
#sett_button {
  cursor: pointer;
}

.day {
    background-color: whitesmoke;
    border: 1px solid gray;
    color: black;
    text-align: center;
    height: 100px;
  }
  
  .dayName {
    font-size: 0.8rem;
    font-weight: 600;
  }
  
  /* @media screen and (max-width: 768px) {
    .dayName {
      font-size: 4px;
    }
  } */
  
  .dayNumber {
    font-size: 0.9em;
  }
  
  .diffrentMonth {
    background-color: rgb(204 204 204);
  }
  
.bgOpenMenu {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(128,128,128 , 0.8);
    z-index: 998;
    top: 0;
    left: 0;
    bottom: 0;
}

.openMenu {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;    
    padding: 10px
}


.mainRow {
    width:100%;
    height: 100%;
    /*position: fixed;*/
}
.row-1 {
margin: 0px auto;
width: 60%;
margin-top: 10px;
position: relative;
}

.mainBarNewCon {
    border: 1px black solid;
    width: 100%;
    }

@media screen and (max-width: 1200px) and (min-width: 768px) {
    .row-1 {
        width: 70%;
        } 
}

@media screen and ( max-width:768px) {
    .row-1 {
        width: 100%;
        } 

@media screen and ( max-width:576px) {
        .openMenu , .input-group-text , .form-control , .form-select {
            font-size: 0.8rem !important;
        }

        
        }
}

.topBar {
padding:5px; 
background-color: #fff ;    
}

.centralBarText {
 background-color:#f0f0f0 ;
}

.dialogBoxCentralBar {
    padding: 1rem;
    background-color:#f0f0f0 ;
}

.dialogBoxCentralBar > * {
    padding-bottom: 0.5rem;
}

.bottomBar {
background-color:#d8d8d8 ; 
/*border-top: 1px solid black;*/   
display:block; 
min-height:51px;    
}

.textareaMessageBox {
    background-color: #f0f0f0;
}

.textareaMessageCounter {
    font-size: 0.8rem;
}

.redText {
    color: red;
}

.buttons_main {
    float: right;
    margin: 10px 30px 10px 0px;    
}
.buttons_main >* {
margin: 0px 5px 0px 5px;    
}

.mainBarDelCon {
    border: 1px black solid;
    width: 100%;
}

.mainBarVisit {
    border: 1px black solid;
    width: 100%;
}

.mainBarDialogBox {
    border: 1px black solid;
    width: 100%;
}

.btn-xsm {
    padding: 0.2rem 0.4rem !important;
    font-size: .75rem !important;
    border-radius: 0.2rem !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important
}

.buttonsBox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.consumerVisitsBox {
    width: 100%;
    background-color: white
}

.visitsHistory {
    height: 6.5rem;
    overflow-y: auto
}

.searchIcon {
    width: 1rem;
    height: 1rem;
    background-image: url("/images/search_64b.png");
    background-size: contain;
    cursor: pointer;
}

.visitRow {
    display: flex;
    align-items: center
}
.visitRowSeconds {
    background-color: #e1deff;
}

.loadingWrapp {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.loading { 
    background-color: rgb(223, 223, 223) !important
}


.visitBoxFuture { 
    position: absolute;
    border: 1px solid black;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: .1rem;
}

.visitBox {
    position: relative;    
    width: 97%;
    margin: 0px auto; 
}

.visitBoxMain {
    text-align: center;
}

.firstLineOfVisitBox {
padding: 0px;
font-size: 0.8rem;
}

.secondLineOfVisitBox {
padding: 0px 5px 0px 5px;
font-size: 0.8rem;
font-weight: bold;
}

.thirdLineOfVisitBox {
padding: 0px;
font-size: 0.8rem
}

.hourText {
    color: black;
    font-size: 0.8rem;
}

.btnsBox {
    display: block;
}

.labelVisits {
position: absolute;
margin-top: -1.9rem;
background-color: #f0f0f0;
}

.mainBoxWorkerName {
    padding: 0rem 1rem;
}

.noGutter {
    --bs-gutter-x: 0rem !important;
}

.mainBoxVisits {
    width: 100%;
    padding-left: 10%;
}

.btnsSrv {
width: 100%;
}

.suggestedBtn {
    cursor: pointer;
}

.suggestedBtn:hover {
    color: white;
    background-color: #343a40;
}

@media screen and (max-width: 768px) {

    .firstLineOfVisitBox {
        font-size: .75rem;
        }
        
        .secondLineOfVisitBox {
        font-size: .75rem;
        }
        
        .thirdLineOfVisitBox {
        font-size: .75rem
        }

  }
.saturdayCalendarStyle {
    background-color: #ffd4d4
}

.sundayCalendarStyle {
    background-color: #ff3e3e
}

.networkInfo {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    color: red
}
.dayNoramlDiv {
    flex: 0 0 auto ;
    width: 14.285% ;
}

.saturdayDiv {
background-color: lightgreen;    
}

.sundayDiv {
    background-color:lightcoral;    
    }
 
.toodayDiv { 
    background-color:rgb(50, 192, 218);      
}

.monthDiv {
    padding: 12px; 
    border: 1px solid rgb(27, 27, 27);
}

.monthCal {
margin: 15px 0px 15px 0px;    
}

.currentMonth { 
    cursor: pointer;
}

.monthName { 
    font-size: 1.5em;
    padding: 4px 0px;
}

.prevMonth , .nextMonth {
    color:rgb(118, 118, 129);
}
.mainSceneCalesdars {
  width: 100%;
  margin: 1rem auto;
}


#W1,
#W2,
#W3,
#W4,
#W5,
#W6 {
  height: 100%;
  width: 100%;
  clear: both;
  padding-left: 3.5%;
}

#D1,
#D2,
#D3,
#D4,
#D5,
#D6,
#D7,
#D8,
#D9,
#D10,
#D11,
#D12,
#D13,
#D14,
#D15,
#D16,
#D17,
#D18,
#D19,
#D20,
#D21,
#D22,
#D23,
#D24,
#D25,
#D26,
#D27,
#D28,
#D29,
#D30,
#D31,
#D32,
#D33,
#D34,
#D35,
#D36,
#D37,
#D38,
#D39,
#D40,
#D41,
#D42 {
  width: 13%;
  height: 100px;
  background-color: #000;
  float: left;
  border: 1px solid #d4d4d4;
  text-align: center;
}

#D1,
#D2,
#D3,
#D4,
#D5,
#D6,
#D7 {
  height: 120px;
  margin-top: 10px;
}

#name_day {
  font-size: 10px;
  color: #d4d4d4;
}

#number_day {
  font-size: 14px;
  color: white;
  display: block;
  margin-top: 5px;
}

.btn1 {
  background-color: white;
  border: 1px solid #808080;
  border-radius: 5px;
  float: left;
  margin: 3px 0px 0px 20px;
}

.topMenuCal {
  background-color: #bbcaff;
  padding: 0.4rem;
}

.mainSceneCal { 
  width: 100%;
}

.manBtns {
  width: 100%;
}

.manBtnsA {
  width: 100%;
}

.elmMenuLeft {
  display: flex;
}

.elmMenuLeft > * {
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: row;
}

.elmMenuRight > * {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: row-reverse;
}

.app_btn1 {
  padding: 2px 6px;
  background-color: white; 
  border: 1px solid #808080; 
  border-radius: 5px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 1rem;
}

@media screen and ( max-width:576px) {
  .app_btn1 {
    padding: 1px 4px;
    font-size: 0.8rem;
  }
  
}

.app_btn1:hover {
  background-color: #86a0fb; 
  }


  .frozenButton {
    background-color: #ff0000 !important; 
    border: 1px solid #808080; 
    cursor:copy;
  }

/*margin: 3px 0px 0px 20px; float: left;*/
.spanText1 {
  background-color: white;
  border: 1px solid #808080;
  border-radius: 5px;
  padding: 2px 6px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  font-size: 1rem;
}

@media screen and ( max-width:576px) {
  .spanText1 {
    padding: 1px 4px;
    font-size: 0.8rem;
  }
}

.selectBox {
  background-color: white;
  border: 1px solid #808080;
  border-radius: 5px;
  width: 7rem;
}

.boxSelectBox {
  height: 100%;
  width: 100%;
}

.calSelect {
  height: 0;
  padding: 2px 6px;
}

@media screen and ( max-width:576px) {
  .calSelect {
    padding: 1px 4px !important;
    font-size: 0.8rem !important;
  }
}
.workerMainBox {
    width: 100%;
    padding: 0.1rem 0.5rem;
}

.worker_main {
padding: 0.5rem; 
border: 1px solid black; 
background-color:#E3E3E3;
}

.workersMenuBar {
    padding: 0.3rem;    
    }

.marginElement {
    margin-top: 0.3rem;
}

.workerNameInput {
font-size: 1rem;
}

.workerRightMenu {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
}  

@media screen and (max-width:1200px) {
    .workerRightMenu {
    align-content: flex-end;
    }      
}

.workerBtn {
    min-width: 10rem;
}
.general {
    position: relative;
    padding: 1rem;
    margin: 1rem;
    border: solid #dee2e6;
    border-width: 1px;

}

.general_grey {
    position: relative;
    padding: 1rem;
    margin: 1rem;
    border: solid #a5a5a5;
    border-width: 1px;
}

.generalLabel {
position: absolute;
margin-top: -1.9rem;
background-color: white;  
}

.generalLabel_grey {
    position: absolute;
    margin-top: -1.9rem;
    background-color: #f0f0f0;  
    }

/* .openMenu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(128,128,128 , 0.8);
    z-index: 999;    
} */

.row-1 {
    margin: 0px auto;
    width: 60%;
    margin-top: 10px;
    position: relative;
    }
    
@media screen and (max-width: 1200px) and (min-width: 768px) {
    .row-1 {
        width: 70%;
        } 
}

@media screen and ( max-width:768px) {
    .row-1 {
        width: 100%;
        } 
}
.mainBar {
border: 1px black solid;
width: 50%;
position: absolute;
}

.mainBarConfigSmsProvider {
    border: 1px black solid;
    width: 100%;
    position: absolute;
}


.topBar {
padding:5px; 
background-color: #fff ;    
}

.centralBar {
 background-color:#f0f0f0 ;
 padding:5px;    
}

.bottomBar {
background-color:#d8d8d8 ; 
/*border-top: 1px solid black;*/   
display:block; 
min-height:51px;    
}

.buttons_main {
    float: right;
    margin: 10px 30px 10px 0px;    
}
.buttons_main >* {
margin: 0px 5px 0px 5px;    
}

.servicesPanel {
    width: 50%;
}

.settingDiv {
padding: 10px 0px 0px 0px;
}

.inputStartCalendarHour { 
max-width: 100px
}
.consumerMainBox {
    padding: 0.1rem 0.5rem
    }

.consumerBox {
padding: 0.5rem 0.3rem; 
border: 1px solid black; 
background-color:#E3E3E3;
}

.consumerTopMenuDiv1 {
   display: flex;
   align-items: center; 
}

.consumerMenuBar {
padding: 0.3rem;    
}

.consumerMenuButtons {
display: grid;
}

.consumerRightMenuPos {
display: flex;
}

@media screen and (max-width:1200px) {
    .consumerRightMenuPos {
    flex-direction: row-reverse;
    }      
}

.consumerBtn {
    min-width: 10rem;
}

.btn-main {
    width: auto;
}

@media screen and (max-width:768px) {
    .btn-main {
        font-size: .7rem !important;
    }    
}
.central {
    width: 100%;
}

.elmCentral , .elmCentral>* {
    padding: 0px !important;
    margin: 0px !important;
}
