.top {
background-color: #3e2bf0;   
color: white; 
font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.infoPanel {
    padding: 2px 6px;
}

.salonName {
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
}


.loginText {
font-size: 0.8rem;
font-weight: 400;
line-height: 1.5;   
}

.visibleBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.visibleMenuRow {
    height: 100%;
}
.visibleMenu {
    background-color: #503eff;
    height: 100%;
}
.visibleBtn {
    cursor: "pointer"
}

.rightPanel {
    display: flex;
}
.userPanel {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
}

.logoutIcon {
    cursor: pointer;
}

.networkIcon , .alertIcon , .logoutIcon{
    width: 1.5rem;
    height: 1.5rem;
}

.alertIcon {
    cursor: pointer;
}

.h100 {
    height: 100%;
}

.clockDiv {
    padding: 0.9rem; 
    text-align: -webkit-right
}

.clockText {
    border: 1px solid #b1b1b1; 
    padding: 0.7rem; 
    width: fit-content;
    font-size: 1rem
    
}

.centralBarMessagePanel {
    padding: 1rem;
}

.colorCentralBarMessagePanel {
    background-color: #f0f0f0 !important
}

@media screen and (max-width: 1200px) and (min-width: 768px) {

}
@media screen and (max-width: 922px) {
    .alertIcon , .networkIcon , .logoutIcon  {
    width: 1.2rem;
    height: 1.2rem;
    }

    .smsToSends {
        font-size: 0.6rem;
    }

    .clockText {
        font-size: .7rem;
        padding: .5rem 
    }


}

@media screen and (max-width: 768px) {
    .visibleMenuMain {
        display: none;
      }  
    .smsToSends {
        display: none;
    }
    .hidden_sm {
        display: none;
    }
    .clock {
        display: none;
    }

  }

@media screen and (min-width: 768px) {
    .visibleMenuMain {
      display: block;
    }
} 

@media screen and ( max-width:576px) {
    .salonName {
        font-size: 0.8rem;
    } 
    .loginText {
        font-size: 0.6rem;
    } 
} 

/*animacja*/
.header {
    position: relative;
    width: 100%;
    height: 80px;
    /*background: linear-gradient(135deg, #3e2bf0, #2b1db0);*/
    background: linear-gradient(135deg, #3e2bf0, #090527);
    overflow: hidden;
}

.header::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 100%;
    top: 0;
    left: -50%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 10%, transparent 70%);
    animation: waveMove 4s linear infinite alternate;
    pointer-events: none; /* PRZEPUŚĆ KLIKNIĘCIA */
}

@keyframes waveMove {
    0% { transform: translateX(-10%) translateY(0); }
    100% { transform: translateX(10%) translateY(5px); }
}
 